import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Link from '../components/Link'

const PhilosophyPageContent = () => (
    <div className="main">
        <section className="section">
            <div className="philosophy-content__bg-header philosophy__mt-56px">
                <div className="philosophy-content__bg-header-filter">
                    <div className="philosophy__container">
                        <div className="philosophy__row philosophy-content__section philosophy__align-items-center philosophy__justify-content-center">
                            <div className="philosophy__col-lg-12 philosophy__col-md-12 philosophy__col-sm-12">
                                <h1 className="philosophy__display-7 philosophy__text-center philosophy__text-white philosophy__mb-2">Our Philosophy</h1>
                                <h4 className="philosophy__text-center philosophy__display-6 philosophy__fw-semibold philosophy__text-white philosophy__my-2 philosophy__d-sm-none">働く場所・時間帯を、働く人が自由に選べるような社会にする</h4>
                                <h4 className="philosophy__text-center philosophy__display-6 philosophy__fw-semibold philosophy__text-white philosophy__my-2 philosophy__d-xl-none philosophy__d-lg-none philosophy__d-md-none">働く場所・時間帯を、働く人が<br></br>自由に選べるような社会にする</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="philosophy-content__bg-triangle-header">
                    <svg className="svg-clip" viewBox="0 0 1 1" preserveAspectRatio="none" width="0" height="0" style={{ verticalAlign: 'bottom' }}>
                        <defs>
                            <clipPath id="clipPolygon" clipPathUnits="objectBoundingBox">
                                <polygon points="0 0, 0.16 0, 0.25 0.8, 0.34 0, 1 0, 1 1, 0 1"></polygon>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
            <div className="philosophy__z-10 philosophy__relative">
                <div className="philosophy__container-wrapper">
                    <div className="philosophy__container">
                        <div className="philosophy__row philosophy__align-items-center philosophy__justify-content-center">
                            <div className="philosophy__col-lg-12 philosophy__col-md-12 philosophy__col-sm-12">
                                <h1 className="philosophy__display-7 philosophy__text-muted philosophy__mb-2 philosophy__text-center">Why We Embrace Our Philosophy?</h1>
                                <div className='philosophy__row philosophy__align-items-center philosophy__justify-content-center'>
                                    <div className='philosophy__col-lg-12 philosophy__col-md-10 philosophy__col-sm-10'>
                                        <h1 className="philosophy__display-7 philosophy__fw-bold philosophy__text-center philosophy__text-dark philosophy__fw-semibold philosophy__d-sm-none">私たちは個人の自由な生き方を尊重し、自律的な組織を実現することが、<br></br>より良い社会の実現につながると信じます。</h1>
                                        <h1 className="philosophy__display-7 philosophy__fw-bold philosophy__text-center philosophy__text-dark philosophy__fw-semibold philosophy__my-2 philosophy__d-xl-none philosophy__d-lg-none philosophy__d-md-none">私たちは個人の自由な生き方を尊重し、<br></br>自律的な組織を実現することが、<br></br>より良い社会の実現につながると信じます。</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="philosophy-content__bg-gray">
                <div className="philosophy-content__bg-triangle-header">
                    <div className="philosophy-content__bg-white">
                        <svg className="svg-clip" viewBox="0 0 1 1" preserveAspectRatio="none" width="0" height="0" style={{ verticalAlign: 'bottom' }}>
                            <defs>
                                <clipPath id="clipPolygon-triangle" clipPathUnits="objectBoundingBox">
                                    <polygon points="0.16 0, 0.25 0.8, 0.34 0"></polygon>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                <h1 className="philosophy__text-muted philosophy__fw-bolder philosophy__text-center philosophy-content__title-header-content philosophy__display-7">What We Offer</h1>
            </div>
            <div className="philosophy-content__bg-triangle-before-content-second">
                <div className="philosophy__container">
                    <div className="philosophy__row philosophy__align-items-center philosophy__col-item-2">
                        <div>
                            <img src="/image/common/philosophy/phil-offer-1.png" className="philosophy-content__img-content philosophy__d-block philosophy__mx-auto"></img>
                            <h1 className="philosophy__display-6 philosophy__text-center philosophy__text-dark philosophy__fw-semibold philosophy__my-4">企業の環境づくり</h1>
                        </div>
                        <div>
                            <img src="/image/common/philosophy/phil-offer-2.png" className="philosophy-content__img-content philosophy__d-block philosophy__mx-auto"></img>
                            <h1 className="philosophy__display-6 philosophy__text-center philosophy__text-dark philosophy__fw-semibold philosophy__my-4">働く人の能力開発</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="philosophy-content__bg-gray">
                <div className="philosophy-content__bg-white philosophy-content__bg-inset">
                    <div className="philosophy-content__bg-inset-wrapper">
                        <div className="philosophy-content__bg-triangle-header">
                        </div>
                    </div>
                </div>
                <h1 className="philosophy__text-muted philosophy__fw-bolder philosophy__text-center philosophy-content__title-header-content philosophy__display-7 philosophy-content__bg-white philosophy__mt-50px">Our Businesses</h1>
            </div>
            <div className="philosophy-content__bg-triangle-before-content-third philosophy-content__bg-white philosophy-content__space-third">
                <div className="philosophy__container">
                    <div className="philosophy__row philosophy__justify-content-center">
                        <div className="philosophy__w-100">
                            <div className="philosophy-content__card-white philosophy-content__card-item philosophy__mb-3">
                                    <div className="philosophy__row philosophy__justify-content-center philosophy__align-items-center">
                                        <div className="philosophy__col-4">
                                            <img src="/image/common/philosophy/ico_service_develop.svg" className="philosophy-content__icon-img philosophy__d-block philosophy__mx-auto philosophy__my-2"></img>
                                        </div>
                                        <div className="philosophy__col-6">
                                            <h2 className="philosophy__display-9 philosophy__text-muted philosophy__text-center philosophy__fw-bold philosophy-content__title-tiles">受託開発</h2>
                                        </div>
                                    </div>
                                    <div className="philosophy__row philosophy__justify-content-center">
                                        <div className="philosophy__col-10">
                                            <h2 className="philosophy__text-dark philosophy__text-center philosophy__mx-3">優秀なメンバーによる<br></br>ソフトウェアの受託開発</h2>
                                        </div>
                                    </div>
                                    <div className="philosophy__row philosophy__justify-content-end">
                                        <div className="philosophy__col-6">
                                            <div class="merit__link-area">
                                                <Link
                                                    to={`/services/#contract-business`}
                                                    className='merit__link philosophy__text-muted philosophy__mx-3 philosophy__fw-bold'
                                                >
                                                    詳しく見る
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="philosophy__w-100">
                            <div className="philosophy-content__card-white philosophy-content__card-item philosophy__mb-3">
                                    <div className="philosophy__row philosophy__justify-content-center philosophy__align-items-center">
                                        <div className="philosophy__col-4">
                                            <img src="/image/common/philosophy/ico_service_support.svg" className="philosophy-content__icon-img philosophy__d-block philosophy__mx-auto philosophy__my-2"></img>
                                        </div>
                                        <div className="philosophy__col-6">
                                            <h2 className="philosophy__display-9 philosophy__text-muted philosophy__text-center philosophy__fw-bold philosophy-content__title-tiles">他社支援・協業</h2>
                                        </div>
                                    </div>
                                    <div className="philosophy__row philosophy__justify-content-center">
                                        <div className="philosophy__col-10">
                                            <h2 className="philosophy__text-dark philosophy__text-center philosophy__mx-2">IT化・リモート/フレックス導入・<br></br>海外進出支援等</h2>
                                        </div>
                                    </div>
                                    <div className="philosophy__row philosophy__justify-content-end">
                                        <div className="philosophy__col-6">
                                            <div class="merit__link-area">
                                                <Link
                                                    to={`/services/#support-service`}
                                                    className='merit__link philosophy__text-muted philosophy__mx-3 philosophy__fw-bold'
                                                >
                                                    詳しく見る
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="philosophy__w-100">
                            <div className="philosophy-content__card-white philosophy-content__card-item philosophy__mb-3">
                                    <div className="philosophy__row philosophy__justify-content-center philosophy__align-items-center">
                                        <div className="philosophy__col-4">
                                            <img src="/image/common/philosophy/ico_service_education.svg" className="philosophy-content__icon-img philosophy__d-block philosophy__mx-auto philosophy__my-2"></img>
                                        </div>
                                        <div className="philosophy__col-6">
                                            <h2 className="philosophy__display-9 philosophy__text-muted philosophy__text-center philosophy__fw-bold philosophy-content__title-tiles">Moba Pro<br></br>IT English</h2>
                                        </div>
                                    </div>
                                    <div className="philosophy__row philosophy__justify-content-center">
                                        <div className="philosophy__col-10">
                                            <h2 className="philosophy__text-dark philosophy__text-center philosophy__mx-3">ITエンジニア向けオンライン<br></br>英会話レッスンサービス</h2>
                                        </div>
                                    </div>
                                    <div className="philosophy__row philosophy__justify-content-end">
                                        <div className="philosophy__col-6">
                                            <div class="merit__link-area">
                                                <Link
                                                    to={`/services/#mobapro-services`}
                                                    className='merit__link philosophy__text-muted philosophy__mx-3 philosophy__fw-bold'
                                                >
                                                    詳しく見る
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
)

const PhilosophyPage = (props) => (
    <Layout
    title="サービス | 株式会社もばらぶ"
    description="株式会社もばらぶの事業内容です。受託開発、開発を効率化するWebサービス、ITエンジニア向け英語教育などを行っています。"
    pathName={props.location.pathname}
  >
    
    <Header />
    <PhilosophyPageContent />
  </Layout>
)

export default PhilosophyPage